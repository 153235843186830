import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../../components/layout'
import PostCta from '../../components/marketing/postCta'

const Snippet = (props) => (
  <Layout>
    <Helmet
      title="Storylava - Signal newsletter"
      meta={[
        {
          name: 'description',
          content:
            'Sign up for the latest from the marketing battlefield, direct to your inbox',
        },
      ]}
    />

    <PostCta context="emailSub" />
  </Layout>
)

export default Snippet
